import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import NewsFeed from '../components/NewsFeed';
import Footer from '../components/Footer';
import '../css/home.css';
import '../index.css';


export default class Home extends Component {
	constructor(props){
		super();
		this.state = {
		}
	}
	render(){
		return(
			<div className='content'>
				<div className='mainText'>
					CA Compliant Water Submeter Monitoring and Billing Platform<br /><br />
					<Link to="/contact" style={{ textDecoration: 'none' }}><div className="callToAction">
						Get Connected Today
					</div></Link>
				</div>
				<Footer />
			</div>
		);
	}
}