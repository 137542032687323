import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import HardwareGalleryCard from '../components/HardwareGalleryCard';
import '../index.css';
import '../css/hardware.css';

export default class Hardware extends Component {
	constructor(props){
		super();
		this.state = {

		}
	}
	render(){
		return(
			<div className="content">
				<div className="hardware">
					<br /><br />
					<p id="top">
						Above Grade Metering offers NextCentury Submetering Systems hardware and installation. A typical system would include submeters connected to a remote reader 
						that doubles as a radio transceiver and communicates to a gateway. The remote reader can be wired to the interior of the unit for easy access by tenants. The 
						gateway communicates all of the data from the submeters to the cloud via wired data connection, wireless (WiFi), and LTE Cellular backup. If no remote reader 
						is required, but monitoring is, a simple transceiver can be installed to communicate meter readings with the gateway.
					</p>
					<div className="hardwareGallery">
						<HardwareGalleryCard content="meter" />
						<HardwareGalleryCard content="reader" />
						<HardwareGalleryCard content="gateway" />
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}