import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../index.css';
import '../css/about.css';

export default class About extends Component {
	constructor(props){
		super();
		this.state = {

		}
	}
	render(){
		return(
			<div className="content">
				<div className="about">
					<span id="top">Above Grade Metering provides utility submetering equipment, installation, monitoring, and tenant billing.</span>
					<br /><br /><br /><br /><br />
					<p id="middle">
						<span id="middle">Background: </span>Frustrated by the lack of service to smaller landlords by the large property management platforms, Above Grade Metering set out to develop a platform to serve landlords with 1 - 100 units 
						bound by California's SB7. This California bill requires new multi-family developments to install water submeters, and bill tenants for their share of water usage. Without a simple method to get that data into landlords' billing 
						platforms, we developed an application to retrieve tenants' water usage, utilizing NextCentury Submetering Systems, compile that data into an easy to read bill that complies with all of the California regulations, and deliver that 
						bill either to the landlord to print & mail, directly to the tenant via email, or exportable to the landlord's billing platform to be submitted however they choose.
					<br /><br />
						With the ability to collect data continuously, we show landlords real-time water usage across properties and units. This allows easy identification of potential leaks, as well as custom alerts that can be sent to the landlord, 
						tenant, or both. For tenants concerned with curtailing unnecessary water usage, and landlords concerned with the liability and headache of a water leak, this platform offers some insight and guidance to help mitigate those worries.
					</p>
					<p id="bottom">
						Partnering with Above Grade Metering will provide peace of mind, reassurance, and accountability for utility usage. We handle procuring hardware, having the hardware certified by the County Weights & Measures Department, installing 
						& programming hardware, and setting up the billing platform for landlords to bill tenants effectively.
					</p>
				</div>
				<Footer />
			</div>
		);
	}
}