import React, { Component } from 'react';
import '../css/footer.css';

export default class Footer extends Component {
	constructor(props){
		super();
		this.state = {

		}
	}
	render(){
		return(
			<div className='footer'>
				&copy; 2024 Above Grade Automation, Inc. - All Rights Reserved.{/* - {window.innerWidth}x{window.innerHeight}*/}
			</div>
		);
	}
}
