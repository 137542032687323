import React from 'react';
//import '../css/contact.css';
const descriptions = { 
	meter: { 
		title: 'Water Submeter',
		caption: 'Cold/Hot Water Submeter',
		image: '../assets/M201C.png'
	},
	reader: {
		title: 'Submeter Remote Reader',
		caption: 'Dual Utility Remote Reader',
		image: '../assets/RR4-TR.jpeg'
	},
	gateway: {
		title: 'Internet Gateway',
		caption: 'Submeter Internet Gateway',
		image: '../assets/GW4.jpeg'
	}
}

export default function HardwareGalleryCard(props) {
	return (
		<div id={props.content} className='hardwareGalleryCard'>
			<img src={descriptions[props.content].image} alt={descriptions[props.content].title} /><br />
			<span>{descriptions[props.content].caption}</span>
		</div>
	);
}