import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../index.css';
import '../css/billingplatform.css';

export default class BillingPlatform extends Component {
	constructor(props){
		super();
		this.state = {

		}
	}
	render(){
		return(
			<div className="content">
				<div className="billingPlatform">
					<span id="top">Intuitive web-based platform to deliver Utility Submetering data and create actionable utility bills for landlords.</span>
					<br /><br /><br /><br /><br />
					<p id="top">
						We have developed an intuitve web-based application to retrieve tenants' real-time water usage, utilizing NextCentury Submetering Systems, compile that data 
						into an easy to read bill that complies with all of the California regulations, and deliver that bill either to the landlord for them to print & mail, directly 
						to the tenant via email, or exportable to the landlord's billing platform to be submitted however they choose.
					</p>
				</div>
				<Footer />
			</div>
		);
	}
}