import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../css/client.css';
import '../index.css';


export default class Client extends Component {
	constructor(props){
		super();
		this.state = {
		}
	}
	render(){
		return(
			<div className='content'>
				<div className='mainText'>
					Billing Platform Coming Soon!<br /><br />
					<Link to="/contact" style={{ textDecoration: 'none' }}><div className="callToAction">
						Learn More
					</div></Link>
				</div>
				<Footer />
			</div>
		);
	}
}