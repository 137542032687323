import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import '../index.css';
import '../css/contact.css';

export default class Contact extends Component {
	constructor(props){
		super();
		this.state = {

		}
	}
	render(){
		return(
			<div className="content">
				<div className="contact">
					<ContactForm formID="LC8y93V0" />
				</div>
				<Footer />
			</div>
		);
	}
}