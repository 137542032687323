import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoFULL from '../assets/AGM_Metering_Logo_Black_Blue.svg';
//import logo from '../assets/MJIlogo.svg';
import '../css/header.css';

const solutionsSubMenu = [
	<Link to="/solutions/B2C" className="subLink">Dispensary Delivery</Link>,
	<Link to="/solutions/B2B" className="subLink">B2B Transport</Link>,
	<Link to="/solutions/3rd-Party" className="subLink">3rd Party Delivery</Link>,
	<Link to="/solutions/On-Site" className="subLink">On-Site Security</Link>
];
const productsSubMenu = [
	<Link to="/products/telematics" className="subLink">Telematics</Link>,
	<Link to="/products/track-and-trace" className="subLink">Track and Trace</Link>,
	<Link to="/products/secure-id" className="subLink">Secure IDs</Link>,
	<Link to="/products/storage" className="subLink">Secure Storage</Link>,
	<Link to="/products/install" className="subLink">Installation</Link>
];
const resourcesSubMenu = [
	<Link to="/resources/laws" className="subLink">Laws & Regulations</Link>,
	<Link to="/resources/demo" className="subLink">Demo</Link>,
	<Link to="/resources/cameras" className="subLink">Camera Systems</Link>,
	<Link to="/resources/dispatch" className="subLink">Dispatch</Link>
];

export default class Header extends Component {
	constructor(props){
		super();
		this.state = {
			showSubMenu: false,
			showMobileMenu: false,
		}
	}
	handleSubMenu = (e) => {
		e.preventDefault();
		switch(e.type){
			case 'mouseenter':
				if(e.target.className === 'dropdownLink'){
					switch(e.target.innerHTML){
						case 'Solutions':
							this.setState({ subMenuContext: solutionsSubMenu});
							break;
						case 'Products':
							this.setState({ subMenuContext: productsSubMenu});
							break;
						case 'Resources':
							this.setState({ subMenuContext: resourcesSubMenu});
							break;
						default:
						break;
					}
					this.setState({ showSubMenu: true });
				}else if(e.target.className === 'staticLink'){
					this.setState({ showSubMenu: false });
				}
				break;
			case 'mouseleave':
				if(e.target.className === 'subMenu' || e.target.parentElement.className === 'subMenu'){
					this.setState({ showSubMenu: false });
				}
				break;
			default:
			break;
		}
	}
	handleMobileMenu = (e) => {
		e.preventDefault();
		switch(e.target.className){
			case 'mobileMenuButton':
				this.setState(prevState => ({ showMobileMenu: !prevState.showMobileMenu }));
				break;
			case 'staticMobileLink':
				this.setState({ showMobileMenu: false });
				if(e.target.innerHTML === 'Home'){
					this.props.history.push('/');
				}else if(e.target.innerHTML === 'Billing Platform'){
					this.props.history.push('/billingplatform');
				}else if(e.target.innerHTML === 'Client Portal'){
					this.props.history.push('/client');
				}else {
					this.props.history.push('/'+e.target.innerHTML.toLowerCase());
				}
				break;
			case 'staticMobileLink bottom':
				this.setState({ showMobileMenu: false });
				this.props.history.push('/'+e.target.innerHTML.toLowerCase());
				break;
			default:
			break;
		}
	}
	render(){
		if(window.innerWidth <= 820){
			return(
				<div className='header'>
					<Link to="/" className='logoFull'><img src={logoFULL} className='logoFull' alt='Above Grade Metering' /></Link>
					<div className='mobileMenu' id={this.state.showMobileMenu ? 'open' : 'closed'}>
						<div className='mobileMenuButton'  id={this.state.showMobileMenu ? 'show' : 'hide'} onClick={this.handleMobileMenu}>Menu</div>
						<div className="staticMobileLink" id={this.state.showMobileMenu ? 'show' : 'hide'} onClick={this.handleMobileMenu}>Home</div>
						<div className="staticMobileLink" id={this.state.showMobileMenu ? 'show' : 'hide'} onClick={this.handleMobileMenu}>Hardware</div>
						<div className="staticMobileLink" id={this.state.showMobileMenu ? 'show' : 'hide'} onClick={this.handleMobileMenu}>Billing Platform</div>
						<div className="staticMobileLink" id={this.state.showMobileMenu ? 'show' : 'hide'} onClick={this.handleMobileMenu}>About</div>
						<div className="staticMobileLink bottom" id={this.state.showMobileMenu ? 'show' : 'hide'} onClick={this.handleMobileMenu}>Contact</div>
					</div>
				</div>
			);
		}else {
			return(
				<div className='header'>
					<Link to="/" className='logoFull'><img src={logoFULL} className='logoFull' alt='Above Grade Metering' /></Link>
					<div className="links">
						<Link to="/hardware" className="staticLink" onMouseEnter={this.handleSubMenu} onMouseLeave={this.handleSubMenu}>Hardware</Link>
						<Link to="/billingplatform" className="staticLink" onMouseEnter={this.handleSubMenu} onMouseLeave={this.handleSubMenu}>Billing Platform</Link>
						<Link to="/about" className="staticLink" onMouseEnter={this.handleSubMenu} onMouseLeave={this.handleSubMenu}>About</Link>
						<Link to="/contact" className="staticLink" onMouseEnter={this.handleSubMenu} onMouseLeave={this.handleSubMenu}>Contact</Link>
						<Link to="/client" className="staticLink" onMouseEnter={this.handleSubMenu} onMouseLeave={this.handleSubMenu}>Client Portal</Link>
					</div>
					<div className="subMenu" id={this.state.showSubMenu ? 'visible' : 'hidden' } onMouseEnter={this.handleSubMenu} onMouseLeave={this.handleSubMenu}>
						{this.state.subMenuContext}
					</div>
				</div>
			);
		}
	}
}