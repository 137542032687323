import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import Hardware from './pages/Hardware';
import BillingPlatform from './pages/BillingPlatform';
import About from './pages/About';
import Contact from './pages/Contact';
import Client from './pages/Client';

export default class App extends Component {
  constructor(props){
    super();
    this.state = {

    }
  }
  render(){
    return (
      <Router>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/hardware' element={<Hardware />} />
          <Route path='/billingplatform' element={<BillingPlatform />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/client' element={<Client />} />
        </Routes>
      </Router>
    );
  }
}